import { change } from 'redux-form';
import getWarehousesById from 'services/wms/getWarehousesById';
import getMotivesById from 'services/management/getMotivesById';
import { makeFetchDataParams } from 'utils';
import { callRequest, getPathFiltersParameters, getQueryFiltersParameters } from 'utils/request';
import { updateUrlFilters } from 'utils/location';
import { executeGetAsyncDependencies } from 'modules/asyncDependencies/actions';
import { createDate, createMonth, createWeek, getTimeRange } from 'utils/date/calendar';
import { types } from './index';

export const createPage = schema => ({
	type: types.CREATE_PAGE,
	schema
});

export const fetchingData = () => ({
	type: types.FETCHING_DATA
});

export const fetchDataSuccess = data => ({
	type: types.FETCH_DATA_SUCCESS,
	data
});

export const setRequiredFilters = () => ({
	type: types.SET_REQUIRED_FILTERS,
	errorStatus: { hasError: true, errorData: { status: 'requiredPlanificationFilter' } }
});

export const setCalendarMode = mode => ({
	type: types.SET_CALENDAR_MODE,
	mode
});

export const setSelectedDay = currentDay => ({
	type: types.SET_SELECTED_DAY,
	currentDay: createDate(currentDay)
});

export const setSelectedWeek = currentWeek => ({
	type: types.SET_SELECTED_WEEK,
	currentWeek: createWeek(currentWeek)
});

export const setSelectedMonth = currentMonth => ({
	type: types.SET_SELECTED_MONTH,
	currentMonth: createMonth(currentMonth)
});

export const setError = status => ({
	type: types.SET_ERROR,
	errorStatus: { hasError: true, errorData: { ...status } }
});

export const getMainData = ({
	appliedFilters,
	schema,
	sorting,
	pageNumber = 1,
	accumulatedData = [],
	hasRequiredFilters,
	callback
}) => async (dispatch, getState) => {
	const {
		planning: { selectedMonth, selectedWeek, calendarMode }
	} = getState();

	const PAGE_SIZE = 100;
	const isWeekly = calendarMode === 'weekly';
	const range = {
		start: isWeekly ? selectedWeek.start : selectedMonth.start,
		end: isWeekly ? selectedWeek.end : selectedMonth.end
	};

	const {
		source = {},
		endpointParameters: schemaEndpointParams = [],
		filters: schemaFilters,
		dependencies,
		name
	} = schema;

	const endpointParameters = [
		{
			name: 'dateRange[from]',
			target: 'filter',
			value: { static: range.start }
		},
		{
			name: 'dateRange[to]',
			target: 'filter',
			value: { static: range.end }
		},
		...schemaEndpointParams
	];

	const { filters } = makeFetchDataParams(appliedFilters);
	const { filters: queryFilters } = getQueryFiltersParameters(endpointParameters) || {};
	const pathParameters = getPathFiltersParameters(endpointParameters);

	const allFilters = {
		...queryFilters,
		...filters
	};
	const hasFilter = !!Object.keys(filters).length;
	const headers = { 'x-janis-page': hasFilter ? 1 : pageNumber, 'x-janis-page-size': PAGE_SIZE };
	updateUrlFilters({ filters, ...sorting }, schemaFilters);

	if (hasRequiredFilters) return dispatch(setRequiredFilters());

	try {
		const data = await callRequest(
			source,
			{ ...sorting, filters: allFilters },
			pathParameters,
			headers
		);

		const combinedData = [...accumulatedData, ...data];

		if (data.length === PAGE_SIZE) {
			return dispatch(
				getMainData({
					appliedFilters,
					schema,
					sorting,
					pageNumber: pageNumber + 1,
					accumulatedData: combinedData,
					hasRequiredFilters
				})
			);
		}

		if (dependencies && combinedData.length)
			dispatch(executeGetAsyncDependencies(dependencies, combinedData, name));

		dispatch(
			fetchDataSuccess({
				data: combinedData,
				hasFilter
			})
		);
		if (callback) {
			callback();
		}

		return combinedData;
	} catch ({ response = {} }) {
		dispatch(setError({ status: response.status }));
	}
};

export const initPage = () => (dispatch, getState) => {
	const { page } = getState();
	const { schema } = page;

	dispatch(createPage(schema));
};

export const changeDay = date => dispatch => {
	dispatch(setSelectedDay(date));
};
export const getDataToEdit = (event, openForm, toggleModal) => async dispatch => {
	const driverId = event.users?.id;
	const warehouseIds = await getWarehousesById(event.warehouseIds);

	const dateRange = {
		from: event.dateStart,
		to: event.dateEnd
	};
	const availabilityLabel = event.availability === 'available' ? 'Available' : 'Not Available';
	dispatch(
		change('planningEdit', 'driverId', {
			value: driverId,
			label: `${event.users.firstname} ${event.users.lastname}`,
			rawLabel: `${event.users.firstname} ${event.users.lastname}`
		})
	);
	dispatch(
		change(
			'planningEdit',
			'warehouseIds',
			warehouseIds.map(warehouse => ({
				value: warehouse.id,
				label: warehouse.name,
				rawLabel: warehouse.name
			}))
		)
	);
	dispatch(change('planningEdit', 'dateRange', dateRange));
	dispatch(
		change('planningEdit', 'availability', {
			value: event.availability,
			label: availabilityLabel,
			rawlabel: availabilityLabel
		})
	);
	dispatch(change('planningEdit', 'comment', event.comment));
	openForm(event);
	toggleModal('');
	const motiveIds = await getMotivesById([event.motiveId]);
	const motiveData = motiveIds.find(motive => motive.id === event.motiveId);
	dispatch(
		change('planningEdit', 'motiveId', {
			value: motiveData?.id,
			label: motiveData?.name,
			rawLabel: motiveData?.name
		})
	);
};

export const newPlanning = (startHour, dateData, state, setState, isFull = false) => dispatch => {
	const dateRange = getTimeRange(dateData, startHour, isFull);

	dispatch(
		change('planningCreate', 'timeSlots', [
			{
				dateRange,
				availability: { value: 'available', label: 'Available', rawLabel: 'Available' }
			}
		])
	);
	setState(!state);
};
