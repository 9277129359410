export default item => {
	if (!item.length) return null;

	const [{ namespace, key, values, id }] = item;

	return {
		name: `translate-${key}`,
		target: {
			service: 'i18n',
			namespace: 'translate',
			method: 'update'
		},
		targetEndpointParameters: [
			{
				name: 'id',
				target: 'path',
				value: {
					static: id
				}
			}
		],
		fields: [
			{
				name: 'namespace',
				label: 'views.field.namespace',
				component: 'Text',
				defaultValue: namespace,
				componentAttributes: {}
			},
			{
				name: 'key',
				label: 'views.field.key',
				component: 'Text',
				defaultValue: key,
				componentAttributes: {}
			},
			{
				name: 'values',
				component: 'MultiInput',
				defaultValue: values,
				componentAttributes: {
					requiredFields: ['en-US', 'es', 'pt-BR']
				}
			}
		]
	};
};
