import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import { DOMAIN } from 'config/endpoints';
import { JWT } from '@janiscommerce/jwt';
import { currentEnvironment } from 'config/environment';
import ms from './api/ms';

const JWT_VALIDATE_ENDPOINT = `https://cdn.id${DOMAIN}/.well-known/jwks.json`;
const ID_COOKIE_NAME = 'JANIS_ID_TOKEN';
const ACCESS_COOKIE_NAME = 'JANIS_ACCESS_TOKEN';

let currentClient = {};
let authCookie;

/**
 * Function for set authentication cookies
 * @param {string} data the API response
 */
export const setAuthCookie = data => {
	const { access_token: accessToken, id_token: idToken } = data;

	const infoAuthJSON = jwt.decode(idToken);

	const expires = new Date(infoAuthJSON.exp * 1000);

	Cookies.set(ID_COOKIE_NAME, idToken, { domain: DOMAIN, expires });
	Cookies.set(ACCESS_COOKIE_NAME, accessToken, { domain: DOMAIN, expires });
};

/**
 * Fuction for remove authentication cookie
 */
export const removeAuthCookie = () => {
	Cookies.remove(ID_COOKIE_NAME, { domain: DOMAIN });
	Cookies.remove(ACCESS_COOKIE_NAME, { domain: DOMAIN });
};

/**
 * Function for get current authentication cookie
 * @returns {string|undefined}
 */
export const getAuthCookie = () => {
	authCookie = Cookies.get(ID_COOKIE_NAME);
	return authCookie;
};

/**
 * Function that validates that the token is legitimate
 * @returns { boolean }
 */
export const getVerifiedCookie = async () => {
	const cookie = getAuthCookie();
	if (!cookie) return false;
	if (currentEnvironment === 'local') return true;

	const withOptionJwt = new JWT({
		jwksUri: JWT_VALIDATE_ENDPOINT
	});

	try {
		const decodedToken = await withOptionJwt.verifyToken(cookie);

		if (!decodedToken || decodedToken.exp * 1000 < Date.now()) return false;

		if (decodedToken) return true;
	} catch (error) {
		removeAuthCookie();
		return false;
	}
};

/**
 * Function for get auth cookie decoded
 * @returns {object|null}
 */
export const getAuthCookieDecoded = () => {
	const cookie = getAuthCookie();
	if (cookie) {
		return jwt.decode(cookie);
	}
	return null;
};

/**
 * function for get authentication cookie basic information
 * @returns {object}
 * @example getAuthCookieData() // { janisClient: "fizzmod" }
 */
export const getAuthCookieData = () => {
	const auth = getAuthCookieDecoded();

	if (currentClient.janisClient) return currentClient;

	const errorResponse = { response: { status: 401 } };

	if (!auth) throw errorResponse;

	if (!auth || auth.exp * 1000 < Date.now()) throw errorResponse;

	const { tcode: janisClient, sub: id } = auth;

	currentClient = { janisClient, id };

	ms.setJanisClient = janisClient;

	return currentClient;
};

/**
 * Check if user is logged
 * @returns {boolean}
 */
export const isLoggedIn = () => {
	try {
		const auth = getAuthCookieDecoded();

		if (!auth) return false;

		if (auth.exp * 1000 < Date.now()) return false;

		return true;
	} catch (error) {
		return false;
	}
};

/**
 * function to get the cookie can be changed from client
 * @returns {boolean}
 */

export const getAuthMultipleClients = () => {
	const cookie = Cookies.get(ACCESS_COOKIE_NAME);
	if (cookie) {
		const cookieData = jwt.decode(cookie);
		return (cookieData && cookieData.hasMultipleClients) || false;
	}
	return false;
};
