import React from 'react';
import PropTypes from 'prop-types';
import { Icon as IconComponent } from '@janiscommerce/ui-web';
import { styles as stylesPropType } from 'utils/prop-types';

const Icon = ({ color, name, size, styles, pathStyles, width, height, viewBox, ...props }) => (
	<IconComponent
		name={name}
		width={width}
		height={height}
		styles={styles}
		color={color}
		viewBox={viewBox}
		size={size}
		pathStyles={pathStyles}
		{...props}
	/>
);

Icon.propTypes = {
	/** Nombre del icono */
	name: PropTypes.string.isRequired,
	/** Color en vista normal, para definir el hover utilizar styles */
	color: PropTypes.string,
	/** Click handler */
	onClick: PropTypes.func,
	/** Ancho y alto para iconos cuadrados. El valor por defecto viene definido como tag en el json de la fuente de iconos */
	size: PropTypes.number,
	/** Estilos para el elemento svg, deben ser definidos con backticks ``,
		 si incluye interpolaciones con funciones se debe utilizar la función css de styled-comopnents */
	styles: stylesPropType,
	/** Estilos para el elemento path, deben ser definidos con backticks ``,
	 si incluye interpolaciones con funciones se debe utilizar la función css de styled-comopnents */
	pathStyles: stylesPropType,
	/** Ancho del icono */
	width: PropTypes.number,
	/** Alto del icono */
	height: PropTypes.number,
	/** ViewBox del icono */
	viewBox: PropTypes.string
};

Icon.defaultProps = {
	color: '#000',
	viewBox: '0 0 1024 1024'
};

export default Icon;
