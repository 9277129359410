export const ADD_FILTERS = 'filters/ADD_FILTERS';
export const CHANGE_FILTER = 'filters/CHANGE_FILTER';
export const CHANGE_FILTERS = 'filters/CHANGE_FILTERS';
export const CLEAR_FILTER = 'filters/CLEAR_FILTER';
export const CLEAR_ALL_FILTERS = 'filters/CLEAR_ALL_FILTERS';
export const APPLY_FILTERS_TOGGLE = 'filters/APPLY_FILTERS_TOGGLE';
export const CREATE_FILTERS = 'filters/CREATE_FILTERS';
export const DELETE_FILTERS = 'filters/DELETE_FILTERS';
export const USE_URL_FILTERS = 'filters/USE_URL_FILTERS';
export const IS_READY = 'filters/IS_READY';
export const APPLY_FILTERS = 'filters/APPLY_FILTERS';
export const ADD_INITIAL_FILTERS_VALUES = 'filters/ADD_INITIAL_FILTERS_VALUES';
export const REMOTE_SELECT_LOADING = 'filters/REMOTE_SELECT_LOADING';
