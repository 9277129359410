import { isAfter, isBefore } from 'date-fns';
import { isEqual } from 'lodash';
import moment from 'moment';

let useUTC = false;

export const setMomentUtc = () => {
	useUTC = true;
};

const utcWrapper = momentElem => (useUTC ? momentElem.utc() : momentElem);

export const formatDate = (date, format = 'DD/MM/YYYY') => utcWrapper(moment(date)).format(format);

export const formatTime = (date, format = 'HH:mm') => utcWrapper(moment(date)).format(format);

export const isDateISOString = str => {
	if (typeof str !== 'string') return false;

	return /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/.test(
		str
	);
};

const baseHours = '(hh?|HH?)';
const validTimeFormats = [baseHours, `${baseHours}:mm?`, `${baseHours}:mm?:(ss?|SS?)?`];

export const formatHasTime = formatVal => {
	if (!formatVal) return false;

	return validTimeFormats.some(timeFormat => {
		const timeRegex = new RegExp(`${timeFormat}$`);
		return timeRegex.test(formatVal);
	});
};

export const processDate = (date, format) => {
	if (!date || !format) return null;

	const hasTime = formatHasTime(format);
	const processedDate = hasTime ? moment.utc(date, format) : moment(date, format);
	return processedDate.toISOString();
};

/**
 * Check if a date has expired.
 * @param {Date | string} dueDate - The final delivery date.
 * @param {Date | string} [expirationDate=new Date()] - The date to compare against for expiration. Defaults to today.
 * @returns {boolean} - Returns true if the dueDate has expired based on the expirationDate.
 */
export const hasExpired = (dueDate, expirationDate = new Date()) => {
	if (!dueDate) return false;
	const due = new Date(dueDate);
	const expire = new Date(expirationDate);
	return isBefore(due, expire);
};

export const datePresets = {
	today: {
		now: moment(),
		start: moment().startOf('day'),
		startFilter: 'today',
		end: moment().endOf('day'),
		endFilter: 'today'
	},
	yesterday: {
		start: moment()
			.subtract(1, 'days')
			.startOf('day'),
		startFilter: 'yesterday',
		end: moment()
			.subtract(1, 'days')
			.endOf('day'),
		endFilter: 'yesterday'
	},
	nextWeek: {
		start: moment().startOf('day'),
		startFilter: 'today',
		end: moment()
			.add(1, 'weeks')
			.endOf('day'),
		endFilter: 'nextWeek',
		single: moment()
			.add(1, 'weeks')
			.startOf('day'),
		singleFilter: 'nextWeek'
	},
	lastWeek: {
		start: moment()
			.subtract(6, 'days')
			.startOf('day'),
		startFilter: 'lastWeek',
		end: moment().endOf('day'),
		endFilter: 'today'
	},
	nextMonth: {
		start: moment().startOf('day'),
		startFilter: 'today',
		end: moment()
			.add(1, 'months')
			.endOf('day'),
		endFilter: 'nextMonth',
		single: moment()
			.add(1, 'months')
			.startOf('day'),
		singleFilter: 'nextMonth'
	},
	lastMonth: {
		start: moment()
			.subtract(1, 'months')
			.startOf('day'),
		startFilter: 'lastMonth',
		end: moment().endOf('day'),
		endFilter: 'today'
	}
};

export const getPresets = (filterValue, format) => {
	const { from: fromParamValue, to: toParamValue } = filterValue;

	const getValue = (value, key) => {
		const from = moment();
		const to = moment();

		const toStartDay = date => date.startOf('day');
		const toEndDay = date => date.endOf('day');

		const makeValues = values => ({ from: values.from.toISOString(), to: values.to.toISOString() });

		switch (value) {
			case 'today':
				return makeValues({ from: toStartDay(from), to: toEndDay(to) })[key];
			case 'yesterday':
				return makeValues({
					from: toStartDay(from.subtract(1, 'days')),
					to: toEndDay(to.subtract(1, 'days'))
				})[key];
			case 'nextWeek':
				return makeValues({
					from: toStartDay(from.add(1, 'weeks')),
					to: toEndDay(to.add(1, 'weeks'))
				})[key];
			case 'lastWeek':
				return makeValues({
					from: toStartDay(to.subtract(6, 'days')),
					to: toEndDay(from.subtract(1, 'weeks'))
				})[key];
			case 'nextMonth':
				return makeValues({
					from: toStartDay(to.add(1, 'months')),
					to: toEndDay(from.add(1, 'months'))
				})[key];
			case 'lastMonth':
				return makeValues({
					from: toStartDay(from.subtract(1, 'months')),
					to: toEndDay(to)
				})[key];
			default: {
				const paramValue = moment(value, isDateISOString(value) ? undefined : format);
				return paramValue.isValid() ? value : moment().toISOString();
			}
		}
	};

	return {
		from: getValue(fromParamValue, 'from'),
		to: getValue(toParamValue, 'to')
	};
};

export const parsePresets = ({ value, isRangePicker, setStart, setEnd, selectTime = false }) => {
	let singleDatePreset;
	if (!datePresets[value]) return;
	const { start, single, end, now } = datePresets[value];
	if (single) singleDatePreset = single.toDate();
	if (setStart) singleDatePreset = start.toDate();
	if (!setStart) singleDatePreset = selectTime ? now.toDate() : start.toDate();
	if (setEnd) singleDatePreset = selectTime ? end.toDate() : start.toDate();
	const rangePreset = { from: start && start.toDate(), to: end && end.toDate() };
	const presetValue = isRangePicker ? rangePreset : singleDatePreset;
	return presetValue;
};

/**
 * Make relative values for date time picker range
 * @param {object} component - Object component
 * @param {object} values - Object filters values
 */
export const makeDateTimePickerRelativeValues = (component, values) => {
	if (!component) return;

	const { name, component: componentName, componentAttributes = {} } = component;

	const filterValue = values[name];

	if (
		componentName === 'DateTimePicker' &&
		componentAttributes.selectRange &&
		componentAttributes.selectDate &&
		typeof filterValue === 'object'
	) {
		return getPresets(filterValue, componentAttributes.format);
	}

	if (
		componentName === 'DateTimePicker' &&
		componentAttributes.selectDate &&
		!componentAttributes.selectRange &&
		typeof filterValue === 'string'
	) {
		return parsePresets({
			value: filterValue,
			setStart: componentAttributes?.setStartOfDay,
			setEnd: componentAttributes?.setEndOfDay,
			selectTime: componentAttributes?.selectTime
		});
	}

	return filterValue;
};

const isDateInRange = (date, start, end) =>
	(isAfter(date, start) && isBefore(date, end)) || isEqual(date, start) || isEqual(date, end);

export const areDatesInRange = (startDate, endDate, rangeStart, rangeEnd) => {
	const startInRange = isDateInRange(startDate, rangeStart, rangeEnd);
	const endInRange = isDateInRange(endDate, rangeStart, rangeEnd);
	return startInRange || endInRange;
};
