import React from 'react';
import PropTypes from 'prop-types';
import translationHOC from 'hocs/translationHOC';
import { colors } from 'theme';
import styled from './styles';

export const UnpaidHeader = ({ t, TextComponent }) => (
	<styled.Container>
		<styled.Icon name="exclamation_triangle" color={colors.white} size={32} />
		<TextComponent color={colors.white}>
			<styled.BoldText>¡{t('common.title.attention')}!</styled.BoldText>
			<styled.NormalText>{t('common.title.subscriptionWillBeCancelled')}</styled.NormalText>
		</TextComponent>
	</styled.Container>
);

UnpaidHeader.propTypes = {
	TextComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired
};

export default translationHOC(UnpaidHeader);
