/* eslint-disable no-redeclare */
import {
	add,
	eachMinuteOfInterval,
	endOfDay,
	format,
	getUnixTime,
	isAfter,
	isBefore,
	isEqual,
	isValid,
	parse,
	parseISO,
	set,
	startOfDay,
	sub,
	getHours,
	toDate,
	getYear,
	getMonth,
	getDate,
	getMinutes,
	getSeconds,
	getMilliseconds
} from 'date-fns';

/**
 * @function isDateObj
 * @description Validates if a date is an object to parse it to ISO or not
 * @param {string | number | DateType} date - A date
 * @returns {string} The date as string
 */
const isDateObj = date => (date instanceof Date ? date : parseISO(date));

/**
 * @function validateDate
 * @description Validates a date using isValid date-fns helper
 * @param {string | number | DateType} date - A date
 * @returns {DateType} The date as Date object
 */
const validateDate = date => (isValid(date) ? date : new Date(date));

/**
 * @function isOnlyHoursAndMinutes
 * @description Validates is a date only contains hours and minutes (in format HH:ss)
 * @param {string | number | DateType} date - A date
 * @returns {boolean} true if the date is hours and minutes, otherwise false
 */
const isOnlyHoursAndMinutes = date => /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(date);

/**
 * @function getDateFromHours
 * @description Sets a Date from an object with hours and minutes
 * @param {string} hoursString - hours and minutes in format HH:mm
 * @returns {DateType} A new Date with the provided time
 */
const setDateFromHours = hoursString => {
	const [hours, minutes] = hoursString.split(':');
	return set(new Date(), { hours, minutes, seconds: 0 });
};

/**
 * @function createDate
 * @description Creates a Date
 * @param {string | number | DateType} date - A date
 * @returns {DateType} A new Date
 */
const createDate = date => (isOnlyHoursAndMinutes(date) ? setDateFromHours(date) : new Date(date));

/**
 * @function convertToLocalTimeToUTC
 * @description Converts local time to UTC
 * @param {DateType} localDate - The local date
 * @returns {DateType} A date with UTC format
 */
const convertToLocalTimeToUTC = localDate => {
	const localTime = localDate.toLocaleTimeString('en-US', { hour12: false });
	const [hours, minutes, seconds] = localTime.split(':').map(Number);
	return new Date(
		Date.UTC(
			localDate.getFullYear(),
			localDate.getMonth(),
			localDate.getDate(),
			hours,
			minutes,
			seconds
		)
	);
};

export default {
	/**
	 * @function startOfDay
	 * @description Get the start of the day
	 * @param {string | number | DateType} date - A date
	 * @returns {DateType} The start of a day
	 */
	startOfDay: date => startOfDay(date),
	/**
	 * @function endOfDay
	 * @description Get the end of the day
	 * @param {string | number | DateType} date - The original date
	 * @returns {DateType} The end of a day
	 */
	endOfDay: date => endOfDay(date),
	/**
	 * @function format
	 * @description Formats a date
	 * @param {string | number | DateType} date - The original date
	 * @param {string} formatString - a format
	 * @param {string} timeZone - Optional time zone
	 * @returns {string} The formatted date string
	 */
	format: (date, formatString, timeZone) => {
		if (!formatString) return date;
		if (timeZone) return format(createDate(date), formatString, { timeZone });
		return format(createDate(date), formatString);
	},
	/**
	 * @function parse
	 * @description Parse a date
	 * @param {string} date - The string to parse
	 * @param {string} formatString - The string of tokens
	 * @param {string | number | DateType} referenceDate - defines values missing from the parsed dateString
	 * @returns {DateType} The parsed date
	 */
	parse: (date, formatString, referenceDate) => parse(date, formatString, referenceDate),
	/**
	 * @function parse
	 * @description Parse a date
	 * @param {Object} date - The interval with format { start, end }
	 * @param {Object} config - An object with options
	 * @returns {Array<DateType>} The array of minutes within the specified time interval
	 */
	eachMinuteOfInterval: (interval, config) => eachMinuteOfInterval(interval, config),
	/**
	 * @function beforeOrAfter
	 * @description Parse a date
	 * @param {string | number | DateType} date - The original date
	 * @param {string | number | DateType} dateToCompare - The date to compare
	 * @param {type} string - if is before or after
	 * @returns {boolean} the result of isBefore or isAfter functions according the type
	 */
	beforeOrAfter: (date, dateToCompare, type = 'before') => {
		const typeFn = type === 'before' ? isBefore : isAfter;
		const parsedDate = isDateObj(date);
		const parsedDateToCompare = isDateObj(dateToCompare);
		return typeFn(parsedDate, parsedDateToCompare);
	},
	/**
	 * @function changeDate
	 * @description Changes the date
	 * @param {string | number | DateType} date - The date to be changed
	 * @param {string} type - if is add or sub
	 * @param {number} number - the number that will be added or subtracted depending the type parameter
	 * @param {time} time - the time to use (seconds, minutes, hours, days, months, years)
	 * @returns {DateType} the new date with the seconds added or subtracted
	 */
	changeDate: (date, type = 'add', number, time) => {
		const changeFn = type === 'add' ? add : sub;
		return changeFn(createDate(date), {
			[time]: number
		});
	},
	/**
	 * @function getUnix
	 * @description Get the seconds timestamp of a given date
	 * @param {string | number | DateType} date - The given date
	 * @returns {number} the timestamp
	 */
	getUnix: date => getUnixTime(parseISO(date)),
	/**
	 * @function isValid
	 * @description Validates a date
	 * @param {unknown} date - The date to check
	 * @returns {boolean} returns false if argument is Invalid Date and true otherwise
	 */
	isValid: date => {
		if (isOnlyHoursAndMinutes(date)) return true;
		if (date instanceof Date || typeof date === 'string') return isValid(new Date(date));
		return isValid(new Date(date.from)) && isValid(new Date(date.to));
	},
	/**
	 * @function isEqual
	 * @description Validates if two dates are equal
	 * @param {string | number | DateType} date - The original date
	 * @param {string | number | DateType} dateToCompare - A date to compare
	 * @returns {boolean} the dates are equal
	 */
	isEqual: (date, dateToCompare) => {
		const parsedDate = isDateObj(date);
		const parsedDateToCompare = isDateObj(dateToCompare);
		return isEqual(parsedDate, parsedDateToCompare);
	},
	/**
	 * @function toDate
	 * @description Convert the given argument to an instance of Date
	 * @param {string | number | DateType} date - The original date
	 * @returns {DateType} a Date object
	 */
	toDate: date => new Date(createDate(date)),
	/**
	 * @function toISOString
	 * @description Formats the date according to the ISO 8601 standard
	 * @param {string | number | DateType} date - The original date
	 * @returns {string} The formatted date string in ISO 8601 format
	 */
	toISOString: date => validateDate(date).toISOString(),
	/**
	 * @function toUTC
	 * @description Converts a date with UTC format and settings
	 * @param {string | number | DateType} date - The original date
	 * @returns {string | number | DateType} a date with UTC format
	 */
	toUTC: date => convertToLocalTimeToUTC(createDate(date)),
	/**
	 * @function local
	 * @description Returns a Date object as a string, using locale settings
	 * @param {string | number | DateType} date - The original date
	 * @returns {string} a Date object using the locale setup on the computer
	 */
	local: date => createDate(date).toLocaleString(),
	/**
	 * @function getTimebytimeStamp
	 * @description Formats a valid timestamp according to the specified format.
	 * @param {number} timeStamp - The timestamp to format.
	 * @param {string} timeFormat - The desired format for the timestamp. It can contain the following placeholders:
	 *    - 'yyyy': Full year (e.g., 2024)
	 *    - 'MM': Month (e.g., 03 for March)
	 *    - 'dd': Day of the month (e.g., 13)
	 *    - 'HH': Hours in 24-hour format (e.g., 17 for 5 PM)
	 *    - 'mm': Minutes (e.g., 45)
	 *    - 'ss': Seconds (e.g., 30)
	 * @param {string} timeZone - Optional time zone
	 * @returns {string | false}
	 */
	getTimeByTimeStamp: (
		timeStamp,
		timeFormat,
		timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
	) => {
		const time = new Date(timeStamp);

		return isValid(time) && format(time, timeFormat, { timeZone });
	},
	/**
	 * Get the hour of a Date object.
	 * @param {Date} date - The Date object from which to get the hour.
	 * @returns {number} - The hour of the Date object (0-23).
	 */
	getHours: date => getHours(date),
	/**
	 * Converts a date object to a date.
	 * @param {Date} date - The date object to convert.
	 * @returns {Date} The converted date object.
	 */
	date: date => toDate(date),
	/**
	 * Gets the year from a date object.
	 * @param {Date} date - The date object from which to get the year.
	 * @returns {number} The year of the date.
	 */
	getYear: date => getYear(date),
	/**
	 * Gets the month (0-11) from a date object.
	 * @param {Date} date - The date object from which to get the month.
	 * @returns {number} The month of the date (0-11).
	 */
	getMonth: date => getMonth(date),
	/**
	 * Gets the day of the month (1-31) from a date object.
	 * @param {Date} date - The date object from which to get the day of the month.
	 * @returns {number} The day of the month of the date (1-31).
	 */
	getDate: date => getDate(date),
	/**
	 * Gets the hours (0-23) from a date object.
	 * @param {Date} date - The date object from which to get the hours.
	 * @returns {number} The hours of the date (0-23).
	 */
	getHour: date => getHours(date),
	/**
	 * Gets the minutes (0-59) from a date object.
	 * @param {Date} date - The date object from which to get the minutes.
	 * @returns {number} The minutes of the date (0-59).
	 */
	getMinutes: date => getMinutes(date),
	/**
	 * Gets the seconds (0-59) from a date object.
	 * @param {Date} date - The date object from which to get the seconds.
	 * @returns {number} The seconds of the date (0-59).
	 */
	getSeconds: date => getSeconds(date),
	/**
	 * Gets the milliseconds (0-999) from a date object.
	 * @param {Date} date - The date object from which to get the milliseconds.
	 * @returns {number} The milliseconds of the date (0-999).
	 */
	getMilliseconds: date => getMilliseconds(date),
	/**
	 * Retrieves the current time zone.
	 * @returns {string} The current time zone.
	 */
	getTimeZone: () => Intl.DateTimeFormat().resolvedOptions().timeZone
};
