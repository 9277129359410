import { getAuthCookieData, getAuthCookieDecoded } from 'utils/auth';
import { callRequest, getPathFiltersParameters, getQueryFiltersParameters } from 'utils/request';
import { actions as userActions } from 'modules/user';
import { getLocationParts } from 'utils/location';
import store from 'store';
import { setToStorage, getFromStorage } from 'utils/storage';
import { get } from 'axios';
import { SCHEMA_ENDPOINT } from 'config/endpoints';
import { getPageSchema } from 'utils/api';
import * as types from './types';

const fetchSchemaRequest = () => ({
	type: types.FETCH_SCHEMA_REQUEST
});

const fetchSchemaSuccess = schema => ({
	type: types.FETCH_SCHEMA_SUCCESS,
	schema
});

const fetchSchemaFailure = status => ({
	type: types.FETCH_SCHEMA_FAILURE,
	status
});

const fetchJanisClientSuccess = janisClient => ({
	type: types.FETCH_JANIS_CLIENT_SUCCESS,
	janisClient
});

const fetchJanisClientFailure = status => ({
	type: types.FETCH_JANIS_CLIENT_FAILURE,
	status
});

export const isSchemaCacheDisabled = () => store.get('schemaCacheDisabled');

export const fetchSchema = () => async dispatch => {
	dispatch(fetchSchemaRequest());
	try {
		const schema = await getPageSchema(undefined, isSchemaCacheDisabled());
		dispatch(fetchSchemaSuccess(schema));
	} catch (error) {
		const { response = {} } = error;
		const { status } = response;
		dispatch(fetchSchemaFailure({ status }));
	}
};

export const getFormattedSections = async (section, data) => {
	if (!section.schemaSource) return section;

	const {
		schemaSource: { type, endpoint, endpointParameters = [] },
		overwriteName,
		name
	} = section;
	let remoteSection;

	try {
		if (type === 'dynamic') {
			const filters = getQueryFiltersParameters(endpointParameters, data, true);
			const sourceEndpointParameters = getPathFiltersParameters(endpointParameters, data);

			const response = await callRequest(endpoint, filters, sourceEndpointParameters);

			// use dynamicSectionRawName for identify the section and remove remote data before save in cache
			remoteSection = { ...response, dynamicSectionRawName: name };
		} else {
			// type = static
			const { service, namespace, method } = endpoint;

			const url = `${SCHEMA_ENDPOINT}/schemas/${service}/${namespace}/sections/${method}.json`;

			const response = await get(url);
			remoteSection = { ...response.data };
		}

		return {
			...remoteSection,
			...section,
			name: overwriteName ? name : remoteSection.name || name,
			remoteComponent: remoteSection.rootComponent,
			schemaSource: false
		};
	} catch {
		return { ...section, schemaSource: false, hasError: true };
	}
};

export const getRemoteSubSection = async (section, data) => {
	const parts = getLocationParts();
	const parsedParts = parts.slice(0, 3).join('/');
	const { name } = section;
	const schemaKey = `cacheSchema-${parsedParts}-subSection_${name}`;
	const cacheSubSection = getFromStorage(schemaKey);
	if (cacheSubSection) return cacheSubSection;

	const dynamicSection = await getFormattedSections(section, data);
	return dynamicSection;
};

export const removeDynamicSections = (formattedSections, rawSections) => {
	const dynamicSections = rawSections.reduce((accum, section) => {
		const accumulator = { ...accum };
		const { schemaSource, name } = section;
		if (!!schemaSource && schemaSource.type === 'dynamic') accumulator[name] = { ...section };

		return accumulator;
	}, {});

	return formattedSections.map(section =>
		section.dynamicSectionRawName ? dynamicSections[section.dynamicSectionRawName] : section
	);
};

export const fetchRemoteSections = () => async (dispatch, getState) => {
	const {
		editCreate: { data },
		page: { schema }
	} = getState();

	const { sections = [] } = schema;

	if (!sections.some(({ schemaSource }) => schemaSource)) return;

	try {
		const [service, namespace, method] = getLocationParts();
		const schemaKey = `cacheSchema-${[service, namespace, method].join('/')}`;

		const formattedSections = await Promise.all(
			sections.map(section => getFormattedSections(section, data))
		);

		const rawDynamicSections = removeDynamicSections(formattedSections, sections);

		const updatedSchema = { ...schema, sections: formattedSections };

		const schemaData = {
			...schema,
			sections: rawDynamicSections
		};

		setToStorage(schemaKey, schemaData, 30);

		dispatch(fetchSchemaSuccess(updatedSchema));
	} catch (error) {
		const { response = {} } = error;
		const { status } = response;
		dispatch(fetchSchemaFailure({ status }));
	}
};

export const fetchClientData = () => async dispatch => {
	try {
		const { janisClient, id } = getAuthCookieData();
		const { locale: tlanguage } = getAuthCookieDecoded() || {};
		const preselectedLanguage = store.get('preselectedLanguage');

		const source = {
			service: 'id',
			namespace: 'account',
			method: 'save'
		};

		const language = preselectedLanguage || tlanguage;

		store.remove('preselectedLanguage');

		if (language !== tlanguage) {
			try {
				localStorage.setItem('selectedLanguage', language);
				await callRequest(source, { lang: language });
			} catch (error) {
				console.error(error);
			}
		}

		// Save janisClient in store
		dispatch(fetchJanisClientSuccess(janisClient));
		// Save userId in store
		dispatch(userActions.fetchUserId(id));
	} catch (error) {
		const { response = {} } = error;
		const { status } = response;
		dispatch(fetchJanisClientFailure({ status }));
		dispatch(userActions.fetchUserIdFailure());
	}
};

export const handlerChangePage = () => ({
	type: types.CHANGE_PAGE
});

export const setRemoteActionsCache = data => ({
	type: types.SET_REMOTE_ACTIONS_CACHE,
	data
});

export const toggleModalStatus = isOpen => ({
	type: types.TOGGLE_MODAL_STATUS,
	status: isOpen
});

export const loadModalInfo = data => ({
	type: types.LOAD_MODAL_INFO,
	data
});
