import { getPageData } from 'utils/api';
import { updateUrlFilters, getParsedParams, getPreviewUrlParams } from 'utils/location';
import { getPathFiltersParameters } from 'utils/request';
import { actions as asyncDependenciesActions } from 'modules/asyncDependencies';
import {
	fetchPreviewData,
	handleSetActiveRow as setPreviewActiveRow
} from 'modules/preview/actions';
import hasRequiredFiltersNotApplied from 'utils/filters/hasRequiredFilters';
import * as types from '../../types';
import { getCurrentBrowse, makeFetchDataParams } from '../actions-utils';
import { hasFiltersApplied } from '../../selectors';
import { checkUrlFilter, clearAllFiltersAction } from '../filters';
import { processAsyncFields, startAsyncFieldsLoading } from '../async-fields';

const fetchDataRequest = () => currentBrowse => ({
	type: types.FETCH_DATA_REQUEST,
	meta: { name: currentBrowse }
});

export const fetchDataSuccess = ({ rows, page, total }) => currentBrowse => ({
	type: types.FETCH_SUCCESS_WITHOUT_TOTAL,
	rows,
	total,
	page,
	meta: { name: currentBrowse }
});

export const fetchSuccessWithoutTotal = ({ rows, page }) => currentBrowse => ({
	type: types.FETCH_DEPENDENCIES_SUCCESS,
	rows,
	page,
	meta: { name: currentBrowse }
});

export const fetchTotalSuccess = ({ page, total }) => currentBrowse => ({
	type: types.FETCH_TOTAL_SUCCESS,
	page,
	total,
	meta: { name: currentBrowse }
});

const fetchDataFailure = status => currentBrowse => ({
	type: types.FETCH_DATA_FAILURE,
	status,
	meta: { name: currentBrowse }
});

export const setEmptyBrowse = (status = true) => currentBrowse => ({
	type: types.SET_EMPTY_BROWSE,
	status,
	meta: { name: currentBrowse }
});

export const setHasPreview = (hasPreview, previewName) => currentBrowse => ({
	type: types.SET_HAS_PREVIEW_SCHEMA,
	hasPreview,
	meta: { name: currentBrowse, preview: previewName }
});

export const setHasRowCollapsePreview = (hasPreview, previewName) => currentBrowse => ({
	type: types.SET_HAS_ROW_COLLAPSE_PREVIEW_SCHEMA,
	hasPreview,
	meta: { name: currentBrowse, preview: previewName }
});

const handlePreviewUrlParams = ({ currentBrowse, rows = [], dispatch }) => {
	const { id } = getPreviewUrlParams();

	if (rows.some(row => row.id === id)) {
		dispatch(setPreviewActiveRow({ activeRow: id, setFromUrl: true })(currentBrowse));
		dispatch(fetchPreviewData(id)(currentBrowse));
	} else {
		dispatch(setPreviewActiveRow({ activeRow: '' })(currentBrowse));
	}
};

export const fetchTotals = ({ page }) => currentBrowse => async (dispatch, getState) => {
	const state = getCurrentBrowse(getState(), currentBrowse);

	const hasRequiredFilters = hasRequiredFiltersNotApplied(state.filters, state.appliedFilters);

	const { editCreate } = getState();

	dispatch(fetchDataRequest()(currentBrowse));

	const currentParams = makeFetchDataParams(state, page);

	const { source, endpointParameters, sourceField, currentViewData, customHeaders } = state.schema;

	const pathParameters = getPathFiltersParameters(endpointParameters, currentViewData);

	const pageDataParams = {
		...currentParams,
		source,
		sourceField,
		customHeaders: { ...customHeaders, 'x-janis-only-totals': true, 'x-janis-totals': true },
		currentViewData: sourceField ? editCreate.data : currentViewData,
		endpointParameters: pathParameters,
		isFetchTotal: true
	};

	const { total } = !hasRequiredFilters && (await getPageData(pageDataParams));

	dispatch(fetchTotalSuccess({ total, page })(currentBrowse));
};

/**
 * Function for get current registers
 * @param {object} currentPageData
 */
export const fetchData = ({
	page = 1,
	updateUrlParams = true,
	currentTotal
} = {}) => currentBrowse => async (dispatch, getState) => {
	const state = getCurrentBrowse(getState(), currentBrowse);

	const hasRequiredFilters = hasRequiredFiltersNotApplied(state.filters, state.appliedFilters);

	const { editCreate } = getState();

	dispatch(fetchDataRequest()(currentBrowse));

	const hasFilters = hasFiltersApplied(state);

	const currentParams = makeFetchDataParams(state, page);

	try {
		// Update filters in url
		if (updateUrlParams && state.useUrlUpdates) updateUrlFilters(currentParams, state.filters);

		const {
			source,
			endpointParameters,
			sourceField,
			currentViewData,
			dependencies,
			customHeaders
		} = state.schema;

		const pathParameters = getPathFiltersParameters(endpointParameters, currentViewData);

		const pageDataParams = {
			...currentParams,
			source,
			sourceField,
			customHeaders,
			currentViewData: sourceField ? editCreate.data : currentViewData,
			endpointParameters: pathParameters
		};

		if (state.pageSize !== 'none') pageDataParams.pageSize = state.pageSize;

		const { rows = [], total } = !hasRequiredFilters && (await getPageData(pageDataParams));

		// Init AsyncWrappers loading
		dispatch(startAsyncFieldsLoading()(currentBrowse));
		dispatch(
			fetchDataSuccess({
				rows: Array.isArray(rows) ? rows : [],
				page,
				total: currentTotal || total || 0
			})(currentBrowse)
		);

		const getRows = () => getCurrentBrowse(getState(), currentBrowse).rows || [];

		if (rows.length)
			dispatch(
				asyncDependenciesActions.executeGetAsyncDependencies(
					dependencies,
					getRows,
					currentBrowse,
					'browse',
					{ total: currentTotal || total, page }
				)
			);

		// Init process for get data for AsnycWrappers
		dispatch(processAsyncFields()(currentBrowse));

		if (!hasFilters && !rows.length && !hasRequiredFilters)
			dispatch(setEmptyBrowse()(currentBrowse));
		// get updated state
		const {
			preview: { hasPreview }
		} = getCurrentBrowse(getState(), currentBrowse);

		if (hasPreview) handlePreviewUrlParams({ currentBrowse, dispatch, rows });
	} catch (e) {
		const { response = {} } = e;
		const cancelRequest = !(e && e.constructor && e.constructor.name === 'Cancel');
		if (cancelRequest) dispatch(fetchDataFailure({ status: response.status })(currentBrowse));
	}
};

/**
 * Function to call function for fetch data
 * @param {boolean} [updateUrlParams=true] updateUrlParams - update params when fetch data
 */
export const handleFetchData = (updateUrlParams = true) => currentBrowse => (
	dispatch,
	getState
) => {
	const state = getCurrentBrowse(getState(), currentBrowse);

	if (!updateUrlParams) dispatch(clearAllFiltersAction()(currentBrowse));

	// Check if has filter applyed in url params
	const parsedParams = getParsedParams(state);

	dispatch(checkUrlFilter(parsedParams, currentBrowse)(currentBrowse));

	const options = { updateUrlParams };

	if ('page' in parsedParams && !Number.isNaN(parseInt(parsedParams.page, 10)))
		options.page = parseInt(parsedParams.page, 10);

	// Fetch request for get registers
	dispatch(fetchData(options)(currentBrowse));
};
